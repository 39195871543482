import axios from 'axios'
import { reject } from '../../../../core/utils'
import config from '../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

/**
 * POST verify email address
 * @param {*} email any email address
 * @returns
 */
const verifySingleEmail = async (data) => {
	const response = await instance.post('/v2/mail/verify', data)
	return response
}

/* ******************************
 * MergeEvents - Merge events and their data/media into one uuid
 ***************************** */

const changeEventCreator = async (data) => {
	const response = await instance.post('/events/changeeventcreator', data)
	return response
}

/* ******************************
 * MergeDuplicateAccount - To accounts with the same email address
 ***************************** */
const mergeDuplicateAccount = async (data) => {
	const response = await instance.post('/accounts/merge', data)
	return response
}

/* ******************************
 * MergeEvents - Merge events and their data/media into one uuid
 ***************************** */
const mergeEvents = async (data) => {
	const response = await instance.post('/events/merge', data)
	return response
}

/* ******************************
 * Unsubscribe User
 ***************************** */
const unsubscribeUser = async (data) => {
	const response = await instance.post('/mail/unsubscribe', data)
	return response
}

/* ******************************
 * Transfer Event
 ***************************** */
const transferEvent = async (data) => {
	const { eventId, newOwnerEmail } = data
	const response = await instance.post(`/v2/events/${eventId}/transfer`, {
		newOwnerEmail,
	})
	return response
}

const requests = {
	verifySingleEmail,
	changeEventCreator,
	mergeDuplicateAccount,
	mergeEvents,
	unsubscribeUser,
	transferEvent,
}

export default requests
