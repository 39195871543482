import React from 'react'
import { Alert, AlertIcon, VStack, AlertTitle } from '@chakra-ui/react'

const AlertSuccessSubmission = ({ title }) => {
	return (
		<Alert status="success" borderRadius="lg" mb="1rem" variant="solid" maxW="700px">
			<AlertIcon />
			<VStack alignItems="flex-start" spacing="0">
				<AlertTitle>{title}</AlertTitle>
			</VStack>
		</Alert>
	)
}

AlertSuccessSubmission.defaultProps = {
	title: 'The changes have been submitted!',
}

export default AlertSuccessSubmission
