import React, { useState } from 'react'
import { Box, Text, Heading, chakra, Flex, Button } from '@chakra-ui/react'
import tools from '../../data'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import Input from '../../../../../ui/molecules/Input'
import InputEmail from '../../../../../ui/molecules/InputEmail'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import { mergeDuplicateAccount } from '../../api/actions'
import BackButton from '../molecules/BackButton'
import AlertSuccessSubmission from '../molecules/AlertSuccessSubmission'
const tool = tools.find((el) => el.id == 'merge-accounts')

const MergeAccounts = () => {
	const dispatch = useDispatch()

	const [submitted, setSubmitted] = useState(false)

	const { watch, control, register, handleSubmit, setError, clearErrors, errors, formState, reset } = useForm({
		defaultValues: {
			email: '',
		},
		resolver: yupResolver(schema),
	})

	const onSubmit = (data) => {
		dispatch(mergeDuplicateAccount(data))
		// Clear form
		reset()
		// Let user know we've submitted the request.
		setSubmitted(true)
		// Reset the form so it can be submitted again after a while.
		setTimeout(() => {
			setSubmitted(false)
		}, 5000)
	}

	return (
		<Box w="full">
			<Box w="full" mb="1rem">
				<BackButton />
				<Heading size="md" as="h3">
					{tool.title}
				</Heading>
				<Text>{tool.description}</Text>
			</Box>

			{submitted && <AlertSuccessSubmission />}

			<Box
				d="inline-flex"
				// maxW="700px"
				mb="1rem"
				bg="gray.50"
				p="0.5rem"
				borderRadius="base"
				fontSize="0.875rem"
				color="gray.500">
				<Text>
					Enter primary email address of the user account you want to keep (all other duplicate accounts will
					be deleted).
				</Text>
			</Box>

			<chakra.form w="full" maxW="300px" onSubmit={handleSubmit(onSubmit)} pb="1rem">
				<InputEmail
					control={control}
					errors={errors}
					name="email"
					label="Primary Email Address"
					placeholder="Email"
					// formState={formSubState}
					setError={setError}
					clearErrors={clearErrors}
				/>

				<Flex w="full" my="1rem" justifyContent="flex-start">
					<Button type="submit" variant="solid" minW="150px" isLoading={formState.isSubmitting}>
						Merge Accounts
					</Button>
				</Flex>
			</chakra.form>
		</Box>
	)
}

export default MergeAccounts
