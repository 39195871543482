import { call, put, takeEvery } from 'redux-saga/effects'
import requests from './requests'
import { MAIL, TOOL } from './constants'
// import { RSVP, MAIL, AUTH, MEDIA, PUBLISHED_MEDIA } from './constants'

// /** Saga to handle creation of RSVP for a user */
// function* handlePostRSVP(action) {
// 	let muteAlert = action.payload.muteAlert || false
// 	delete action.payload.muteAlert
// 	try {
// 		var user = {
// 			email: action.payload.email,
// 			profile: {
// 				firstName: action.payload.firstName,
// 			},
// 		}
// 		const response = yield call(requests.postRSVP, action.payload)
// 		yield put({ type: RSVP.POST_SUCCEEDED, payload: { data: { ...response.data.data, user: user } } })
// 		if (!muteAlert) {
// 			yield put({
// 				type: 'ADD_FLASH_MESSAGE',
// 				status: 'success',
// 				message: action.payload.notifyInvite
// 					? `Invitation sent to ${action.payload.email}`
// 					: 'Added to participant list',
// 			})
// 		}
// 	} catch (e) {
// 		yield put({ type: RSVP.POST_FAILED, payload: e })
// 		if (!muteAlert) {
// 			yield put({
// 				type: 'ADD_FLASH_MESSAGE',
// 				status: 'error',
// 				message: `Could not add ${action.payload.email} to list`,
// 			})
// 		}
// 	}
// }

// export function* watchPostRSVP() {
// 	// while(true){
// 	yield takeEvery(RSVP.POST_REQUESTED, handlePostRSVP)
// 	// }
// }

// /** Saga to handle creation of RSVP for a user */
// function* handleUpdateRSVP(action) {
// 	try {
// 		const response = yield call(requests.updateRSVP, action.payload)
// 		yield put({ type: RSVP.UPDATE_SUCCEEDED, payload: { data: response.data.data } })
// 	} catch (e) {
// 		yield put({ type: RSVP.UPDATE_FAILED, payload: e })
// 	}
// }
// export function* watchUpdateRSVP() {
// 	// while(true){
// 	yield takeEvery(RSVP.UPDATE_REQUESTED, handleUpdateRSVP)
// 	// }
// }

// /** MAILERS */

// /** Saga to handle sending of Invitation to a user */
// function* handleSendInvite(action) {
// 	try {
// 		const response = yield call(requests.postInviteEmail, action.payload)
// 		yield put({ type: MAIL.POST_INVITE_SUCCEEDED, payload: { data: response.data.data } })
// 		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'success', message: 'Invite successfully resent' })
// 	} catch (e) {
// 		yield put({ type: MAIL.POST_INVITE_FAILED, payload: e })
// 		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'error', message: 'Invite failed to resend' })
// 	}
// }
// export function* watchSendInvite() {
// 	// while(true){
// 	yield takeEvery(MAIL.POST_INVITE_REQUESTED, handleSendInvite)
// 	// }
// }

// /** Saga to handle sending of Reminder to a user */
// function* handleSendReminder(action) {
// 	try {
// 		const response = yield call(requests.postReminderEmail, action.payload)
// 		yield put({ type: MAIL.POST_REMINDER_SUCCEEDED, payload: { data: response.data.data } })
// 		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'success', message: 'Reminder successfully sent' })
// 	} catch (e) {
// 		yield put({ type: MAIL.POST_REMINDER_FAILED, payload: e })
// 		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'error', message: 'Reminder failed to send' })
// 	}
// }
// export function* watchSendReminder() {
// 	// while(true){
// 	yield takeEvery(MAIL.POST_REMINDER_REQUESTED, handleSendReminder)
// 	// }
// }

// /** Saga to handle sending of Published email to a user */
// function* handleSendPublishedNotice(action) {
// 	try {
// 		const response = yield call(requests.postPublishedEmail, action.payload)
// 		yield put({ type: MAIL.POST_PUBLISHED_SUCCEEDED, payload: { data: response.data.data } })
// 		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'success', message: 'Email successfully sent' })
// 	} catch (e) {
// 		yield put({ type: MAIL.POST_PUBLISHED_FAILED, payload: e })
// 		yield put({ type: 'ADD_FLASH_MESSAGE', status: 'error', message: 'Email failed to send' })
// 	}
// }
// export function* watchSendPublishedNotice() {
// 	// while(true){
// 	yield takeEvery(MAIL.POST_PUBLISHED_REQUESTED, handleSendPublishedNotice)
// 	// }
// }

/** Saga to handle verify email address */
function* handleVerifySingleEmail(action) {
	try {
		const response = yield call(requests.verifySingleEmail, action.payload)
		yield put({ type: MAIL.POST_VERIFY_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: MAIL.POST_VERIFY_FAILED, payload: e })
	}
}
export function* verifySingleEmail() {
	yield takeEvery(MAIL.POST_VERIFY_REQUESTED, handleVerifySingleEmail)
}

/** Update the creator account linked to an event */
function* handleChangeEventCreator(action) {
	try {
		const response = yield call(requests.changeEventCreator, action.payload)
		console.log(response.data, 'response.data!')
		yield put({ type: TOOL.CHANGE_EVENT_CREATOR_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.CHANGE_EVENT_CREATOR_FAILED, payload: e })
	}
}
export function* changeEventCreator() {
	yield takeEvery(TOOL.CHANGE_EVENT_CREATOR_REQUESTED, handleChangeEventCreator)
}

/** Merge Duplicate Account/User */
function* handleMergeDuplicateAccount(action) {
	try {
		const response = yield call(requests.mergeDuplicateAccount, action.payload)
		console.log(response.data, 'response.data!')
		yield put({ type: TOOL.MERGE_DUPLICATE_ACCOUNT_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.MERGE_DUPLICATE_ACCOUNT_FAILED, payload: e })
	}
}
export function* mergeDuplicateAccount() {
	yield takeEvery(TOOL.MERGE_DUPLICATE_ACCOUNT_REQUESTED, handleMergeDuplicateAccount)
}

/** Merge Events  */
function* handleMergeEvents(action) {
	try {
		const response = yield call(requests.mergeEvents, action.payload)

		yield put({ type: TOOL.MERGE_EVENTS_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.MERGE_EVENTS_FAILED, payload: e })
	}
}
export function* mergeEvents() {
	yield takeEvery(TOOL.MERGE_EVENTS_REQUESTED, handleMergeEvents)
}

/** Unsubscribe User  */
function* handleUnsubscribeUser(action) {
	try {
		const response = yield call(requests.unsubscribeUser, action.payload)

		yield put({ type: TOOL.UNSUBSCRIBE_USER_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.UNSUBSCRIBE_USER_FAILED, payload: e })
	}
}
export function* unsubscribeUser() {
	yield takeEvery(TOOL.UNSUBSCRIBE_USER_REQUESTED, handleUnsubscribeUser)
}

/** Transfer Event  */
function* handleTransferEvent(action) {
	try {
		const response = yield call(requests.transferEvent, action.payload)

		yield put({ type: TOOL.TRANSFER_EVENT_SUCCEEDED, payload: { data: response.data.data } })
		return response.data.data
	} catch (e) {
		yield put({ type: TOOL.TRANSFER_EVENT_FAILED, payload: e })
	}
}
export function* transferEvent() {
	yield takeEvery(TOOL.TRANSFER_EVENT_REQUESTED, handleTransferEvent)
}
