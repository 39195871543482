import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded'
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded'
import UnsubscribeRoundedIcon from '@mui/icons-material/UnsubscribeRounded'
import WifiProtectedSetupRoundedIcon from '@mui/icons-material/WifiProtectedSetupRounded'

const tools = [
	//	{
	//		id: 'event-creator',
	//		icon: PermContactCalendarRoundedIcon,
	//		title: 'Change Event Creator',
	//		description: 'Use this tool to change the creator of an event.',
	//		target: '/core/tools/event-creator',
	//	},
	{
		id: 'merge-accounts',
		icon: PeopleRoundedIcon,
		title: 'Merge Accounts',
		description: 'Use this tool to merge two user accounts together.',
		target: '/core/tools/merge-accounts',
	},
	{
		id: 'merge-events',
		icon: EventRepeatRoundedIcon,
		title: 'Merge Events',
		description: 'Use this tool to merge two events together.',
		target: '/core/tools/merge-events',
	},
	{
		id: 'unsubscribe-user',
		icon: UnsubscribeRoundedIcon,
		title: 'Unsubscribe a user',
		description: 'Use this tool to unsubscribe a user from any non-transactional mailing list.',
		target: '/core/tools/unsubscribe-user',
	},
	{
		id: 'transfer-event',
		icon: WifiProtectedSetupRoundedIcon,
		title: 'Transfer Event',
		description: 'Use this tool to transfer an event to a different user.',
		target: '/core/tools/transfer-event',
	},
]

export default tools
