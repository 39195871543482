import * as Yup from 'yup'

export default Yup.object().shape({
	primaryUuid: Yup.string()
		.trim()
		.matches(/^[a-zA-Z0-9_-]{6,8}$/, 'UUID must be 6 or 8 characters')
		.required(),
	secondaryUuid: Yup.string()
		.trim()
		.matches(/^[a-zA-Z0-9_-]{6,8}$/, 'UUID must be 6 or 8 characters')
		.required(),
})
